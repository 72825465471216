import React from 'react';
import Layout from "../components/Layout";
import Link from "next/link";

function Custom404(props) {
	return (
		<Layout title="404 not found">
			<div className="App m-auto pb-20">
				<section className="flex items-center h-full p-16" style={{marginBottom:'5em'}}>
					<div className="container flex flex-col items-center justify-center px-5 mx-auto" style={{marginTop:'6em'}}>
						<div className="max-w-md text-center">
							<h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600" style={{fontSize:'7rem',marginBottom:'.3em'}}>
								404
							</h2>
							<p className="text-2xl font-semibold md:text-3xl">Sorry, we couldn&apos;t find this page.</p>
							<p className="mt-4 mb-8 dark:text-gray-400">This is an invalid link.</p>
							<Link rel="noopener noreferrer" href="/login" className=" btn-primary py-2 px-10">&larr; Back to Login</Link>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
}

export default Custom404;
